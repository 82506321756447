import { useState } from "react";
import { getFunctions, httpsCallable } from 'firebase/functions';
import Input from "./Input";
import Modal from "./Modal";
import Loader from "./Loader";
import Error from "components/Error";
import Lottie from 'lottie-react';
import animationData from 'assets/lottie/email_2.json';

const sendContact = httpsCallable(getFunctions(), 'sendContact');

const ContactModal = ({ setOpen }) => {
    const [messageReceived, setMessageReceived] = useState();
    const [wait, setWait] = useState();
    const [err, setErr] = useState();
    
	const [formData, setFormData] = useState({
		name: "",
		email: "",
		message: "",
	});

	const handleSubmit = async(e) => {
		e.preventDefault();
        setWait(true);
        setErr(null)
        try{
            await sendContact({
                name: formData.name,
                email: formData.email,
                msg: formData.msg
            });
            setMessageReceived(true);
        }
        catch(err){
            setErr(err.message)
        }
        setWait(false);
	}

	return (
		<Modal
			setOpen={setOpen}
		>
			<div className="max-w-[401px] mx-auto text-xl">
				{!messageReceived ? (
					<>
						<form onSubmit={handleSubmit}>
                        <div className="text-center text-lg mb-8 pb-4 border-b">contact@toonkids.com</div>
							<div>
								<Input
									className="text-md mb-8"
									placeholder="Name"
									value={formData.name}
									onChange={(e) =>
										setFormData((prev) => ({
											...prev,
											name: e.target.value,
										}))
									}
                                    disabled={wait}
								/>
								<Input
									className="text-md mb-8"
                                    type='email'
									placeholder="Email"
									value={formData.email}
									onChange={(e) =>
										setFormData((prev) => ({
											...prev,
											email: e.target.value,
										}))
									}
                                    disabled={wait}
								/>
								<Input
									className="text-md mb-8"
									placeholder="Your Message"
									textarea
									value={formData.message}
									onChange={(e) =>
										setFormData((prev) => ({
											...prev,
											message: e.target.value,
										}))
									}
                                    disabled={wait}
								/>
                                <div className="relative flex justify-center items-center">
                                    <button
                                        type="submit"
                                        className="btn h-[59px] w-full max-md:text-md"
                                        disabled={wait}
                                    >
                                        {wait?'Sending':'Send'}
                                    </button>
                                    {wait && <div className="absolute"><Loader/></div>}
                                </div>
								<Error err={err}/>
							</div>
						</form>
					</>
				) : (
					<div className="flex flex-col justify-center items-center">
						<div className="text-center">
							We’ve received your message and will get back to you as
							soon as possible! 🥰
						</div>
						<Lottie
                            animationData={animationData}
                            loop={true}
                            style={{ 
                                width: 200
                            }}
                        />
					</div>
				)}
			</div>
		</Modal>
	);
};

export default ContactModal;
