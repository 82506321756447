import { MdAddPhotoAlternate } from 'react-icons/md';

const FileSelector = () => {
    return (
        <>
            
            <MdAddPhotoAlternate size={60} className="text-gray-400" />
            <p className="font-jua text-lg text-gray-500 mt-2">Select a picture</p>
        </>
    )
};

export default FileSelector;
